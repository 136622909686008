import React from 'react'

export default function SchoolBox(props) {
    return (
        <div className="box-wrap">
            <h2>{props.heading}</h2>
            <p className="mtb-20">{props.subHeading}</p>
            {props.button1 && <button className="button" onClick={() => props.signupAs(process.env.GATSBY_STUDENT_ROLE_ID)}>{props.button1}</button>}
        </div>
    )
}
