import React, {useState} from "react"
import { Link } from "gatsby"
import List from "../CommonComponents/list"
import SingleLi from "../CommonComponents/singleLi"
import "./Footer.scss"


const Footer = () => {

    const [currentYear] = useState(new Date().getFullYear())

    return (
        <footer className="footer-wrap">
            <section className="footer footer--container">
                <section className="footer--links">
                    <section className="footer--icons">
                        <Link to='/'><section className="footer--logo"><img src="/logo-blue.svg" alt="logo" /></section></Link>
                        <ul className="footer--socialmedia">
                            <SingleLi href="https://www.facebook.com/Dynosio/" src="/facebook.svg" alt="facebook"/>
                            <SingleLi href="https://twitter.com/dynosio" src="/twitter.svg" alt="twitter"/> 
                            <SingleLi href="https://www.youtube.com/channel/UCzIE3RB6CQpdxL4BYAp2d9w/" src="/youtube.svg" alt="youtube"/> 
                            <SingleLi href="https://www.linkedin.com/company/dynosio/" src="/linkedin.svg" alt="linkedin"/>
                            <SingleLi href="https://vimeo.com/dynosio" src="/vimeo.svg" alt="vimeo"/>
                        </ul>
                    </section>
                    <section className="footer--menu">
                        <List link1="Dynos Blossoms" link2="Dynos Grade 1 to 8" link3="benefits for students" />
                        <List link1="benefits for teachers" link2="benefits for schools" link3="benefits for parents"/>
                        <List link1="privacy policy" link2="accessibility policy" link3="terms & conditions" />
                        <List link1="contact us"/>
                    </section>
                </section>
                <span className="divider"></span>
                <p className="footer--rights">© {currentYear} Dynos Inc. All Rights Reserved.</p>
            </section>
        </footer>
    )
}
export default Footer