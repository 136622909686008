import React from "react"
import { Link } from "gatsby"

const List = (props) => {
    const str = "/"
    // Use below commented code when there are more patterns to be removed
    // const toRemove = ["policy", "& conditions"]
    // toRemove.forEach(removeItem => props.link1.includes(removeItem))
    let str1 = props.link1.includes("policy") ? props.link1.replace("policy","").replace(/\s+/g, '').trim() : props.link1.replace(/\s+/g, '').trim().toLowerCase()
    let str2 = ''
    if(props.link2) {
        str2 = props.link2.includes("policy") ? props.link2.replace("policy","").replace(/\s+/g, '').trim() : props.link2.includes("Dynos Grade 1 to 8") ? "dynosprimary" : props.link2.replace(/\s+/g, '').trim().toLowerCase()
    }
    let str3 = ''
    let link3 = ''
    if(props.link3){
        str3 = props.link3.includes("& conditions") ? props.link3.replace("& conditions","").replace(/\s+/g, '').trim() : props.link3.replace(/\s+/g, '').trim()
    }
    const link1 = str.concat(str1)
    const link2 = str.concat(str2)
    link3 = str3.length > 0 ? str.concat(str3) : str3
    if(link3.length > 0){
        return (
            <ul>
                <li><Link to={link1} activeClassName="nav-tab-active">{props.link1}</Link></li>
                <li><Link to={link2} activeClassName="nav-tab-active">{props.link2}</Link></li>
                <li><Link to={link3} activeClassName="nav-tab-active">{props.link3}</Link></li>
            </ul>
        )        
    }
    else{
        return (
            <ul>
                <li><Link to={link1} activeClassName="nav-tab-active">{props.link1}</Link></li>
                <li><Link to={link2} activeClassName="nav-tab-active">{props.link2}</Link></li>
            </ul>
        )
    }
    
}
export default List