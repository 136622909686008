import React from 'react'
import { Link } from "gatsby"

export default function IndividualBox(props) {
    return (
        <div className="box-wrap">
            {!!props.heading && <h2>{props.heading}</h2>}
            <div className='individual-button-wrap mtb-20'>
                {!!props.button1 && <button className="individualbox-button mr-10"><a href={process.env.GATSBY_MGMT_LOGIN} target="_self">{props.button1}</a></button>}
                {!!props.button2 && <button className="individualbox-button mr-10"><a href={process.env.GATSBY_B2C_LMS_URL}>{props.button2}</a></button>}
                {!!props.button3 && <button className="individualbox-button"><a href={process.env.GATSBY_CODING_LOGIN}>{props.button3}</a></button>}
                {!!props.button4 && <button className="individualbox-button"><Link className="" to='/signin'>{props.button4}</Link></button>}
            </div>
        </div>
    )
}