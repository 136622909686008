import React from "react"
import Layout2 from "../layouts/layout2"
import IndividualBox from "../components/IndividualBox"
import SchoolBox from "../components/SchoolBox"

const Signup = () => {
  return (
    <Layout2>
      <section className="main--wrap ptb-100">
          <section className="content-wrap">
            <h2 className="title dark">Sign Up!</h2>
            <div className="box-content-wrap">
              <IndividualBox heading={"Individual Sign Up"} button1={"Teacher"} button2={"Student"}/>
              <hr />
              <SchoolBox heading={"School Sign Up"} subHeading={"If your school is registered with Dynos, please use Login for access to your account."} button1={"Login"}/>
            </div>
          </section>
          <section className="img-wrap">
            <img src="/Clip_online_education_by_Icons8.gif" className="signup-img" alt="online education" />
          </section>
      </section>
    </Layout2>
  )
}
export default Signup
