import React from "react";
import Header from '../components/Header'
import Footer from '../components/Footer'

 const Layout2 = ({children}) => {
    return (
        <section className="bg">
            <Header headerClass="headercolor" className="benefits"/>
            <section className="layout2 layout2--container"><main>{children}</main></section>
            <Footer />
        </section>
    )
}
export default Layout2